import Layout from '@/layout';
var settingRouter = {
  path: '/setting',
  component: Layout,
  redirect: '/setting/prescription',
  name: 'setting',
  meta: {
    title: '设置',
    icon: 'setting'
  },
  children: [{
    path: 'doctor',
    name: 'settingMedicalDoctor',
    meta: {
      title: '医生配置',
      groupTitle: '医疗服务'
    },
    redirect: '/setting/doctor/platform',
    component: function component() {
      return import('@/views/setting/medical/doctor/index');
    },
    children: [{
      path: 'platform',
      name: 'settingMedicalDoctorPlatform',
      component: function component() {
        return import('@/views/setting/medical/doctor/platform');
      },
      meta: {
        title: '平台医生配置'
      }
    }, {
      path: 'followUp',
      name: 'settingMedicalDoctorFollowUp',
      component: function component() {
        return import('@/views/setting/medical/doctor/followUp');
      },
      meta: {
        title: '开药科室配置'
      }
    }]
  }, {
    path: 'page',
    name: 'settingPage',
    component: function component() {
      return import('@/views/setting/medical/page/index');
    },
    meta: {
      title: '页面配置'
    }
  }, {
    path: 'prescription',
    name: 'settingMedicalPrescription',
    component: function component() {
      return import('@/views/setting/medical/prescription');
    },
    meta: {
      title: '处方单配置'
    }
  }, {
    path: 'like',
    name: 'settingMedicalLike',
    component: function component() {
      return import('@/views/setting/medical/like');
    },
    meta: {
      title: '患者关注病症配置'
    }
  }, {
    path: 'quick',
    name: 'settingSearchQuick',
    meta: {
      title: '快捷搜索',
      groupTitle: '搜索设置'
    },
    redirect: '/setting/quick/doctor',
    component: function component() {
      return import('@/views/setting/search/quick/index');
    },
    alwaysShow: true,
    children: [{
      path: 'doctor',
      name: 'settingSearchQuickDoctor',
      component: function component() {
        return import('@/views/setting/search/quick/doctor');
      },
      meta: {
        title: '医生页科室分类搜索'
      }
    }, {
      path: 'disease',
      name: 'settingSearchQuickDisease',
      component: function component() {
        return import('@/views/setting/search/quick/disease');
      },
      meta: {
        title: '疾病与症状搜索'
      }
    }, {
      path: 'book',
      name: 'settingSearchQuickBook',
      component: function component() {
        return import('@/views/setting/search/quick/book');
      },
      meta: {
        title: '电子书搜索'
      }
    }]
  }, {
    path: 'tools',
    name: 'settingSearchTools',
    component: function component() {
      return import('@/views/setting/search/searchTools/tools');
    },
    meta: {
      title: '搜索工具配置'
    }
  }, {
    path: 'excelFormList',
    name: 'excelFormList',
    component: function component() {
      return import('@/views/setting/excelForm/index');
    },
    meta: {
      title: '报表导出配置',
      groupTitle: "报表设置"
    }
  }, {
    path: 'staff',
    name: 'settingSystemStaff',
    meta: {
      title: '员工管理',
      groupTitle: '系统管理'
    },
    alwaysShow: true,
    component: function component() {
      return import('@/views/setting/system/staff/index');
    },
    children: [{
      path: 'admin',
      name: 'settingSystemStaffAdmin',
      meta: {
        title: '管理员列表'
      },
      component: function component() {
        return import('@/views/setting/system/staff/admin');
      }
    }]
  }, {
    path: 'role',
    name: 'settingSystemRole',
    meta: {
      title: '权限管理'
    },
    redirect: '/setting/role/part',
    component: function component() {
      return import('@/views/setting/system/role/index');
    },
    children: [{
      path: 'part',
      name: 'settingSystemRolePart',
      component: function component() {
        return import('@/views/setting/system/role/part');
      },
      meta: {
        title: '角色列表'
      }
    }, {
      path: 'api',
      name: 'settingSystemRoleApi',
      component: function component() {
        return import('@/views/setting/system/role/api');
      },
      meta: {
        title: '接口列表'
      }
    }]
  }, {
    path: 'approval',
    name: 'settingApproval',
    meta: {
      title: '审批管理'
    },
    redirect: '/setting/approval/record',
    component: function component() {
      return import('@/views/setting/system/approval/index');
    },
    children: [{
      path: 'record',
      name: 'settingApprovalRecord',
      component: function component() {
        return import('@/views/setting/system/approval/record/index');
      },
      meta: {
        title: '审批记录'
      }
    }, {
      path: 'recordDetail',
      name: 'settingApprovalRecordDetail',
      component: function component() {
        return import('@/views/setting/system/approval/record/recordDetail');
      },
      meta: {
        title: '审批详情'
      },
      hidden: true
    }, {
      path: 'process',
      name: 'settingApprovalProcess',
      component: function component() {
        return import('@/views/setting/system/approval/process');
      },
      meta: {
        title: '审批流程管理'
      }
    }]
  }, {
    path: 'message',
    name: 'settingSystemMessage',
    component: function component() {
      return import('@/views/setting/message/coupon/index');
    },
    meta: {
      title: '消息管理'
    }
  }, {
    path: 'universal',
    name: 'settingSystemUniversal',
    component: function component() {
      return import('@/views/setting/universal/notice/index');
    },
    meta: {
      title: '通用设置'
    }
  }]
};
export default settingRouter;