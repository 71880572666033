import Layout from '@/layout';
var medicalServiceRouter = {
  path: '/treatmentService',
  component: Layout,
  name: 'treatmentService',
  redirect: '/treatmentService/medicalList/mail',
  meta: {
    title: '医疗服务',
    icon: 'treatmentService'
  },
  children: [{
    path: 'medicalList',
    name: 'medicalServiceList',
    meta: {
      title: '列表',
      groupTitle: '药品'
    },
    redirect: '/treatmentService/medicalList/mail',
    component: function component() {
      return import('@/views/treatmentService/list/index');
    },
    children: [{
      path: 'mail',
      component: function component() {
        return import('@/views/treatmentService/list/mail');
      },
      name: 'medicalServiceListMail',
      meta: {
        title: '商城药品列表'
      }
    }, {
      path: 'addErpDrug',
      component: function component() {
        return import('@/views/treatmentService/list/addErpDrug');
      },
      name: 'medicalServiceMailAction',
      meta: {
        title: '添加/修改药品',
        noCache: true,
        activeMenu: '/treatmentService/medicalList/mail'
      },
      hidden: true
    }, {
      path: 'create',
      component: function component() {
        return import('@/views/treatmentService/list/create');
      },
      name: 'medicalServiceMailListCreate',
      meta: {
        title: '添加商城药品',
        icon: 'edit',
        roles: ['editor']
      },
      hidden: true
    }, {
      path: 'edit/:id(\\d+)',
      component: function component() {
        return import('@/views/treatmentService/list/edit');
      },
      name: 'medicalServiceMailListEdit',
      meta: {
        title: '编辑商城药品',
        noCache: true,
        activeMenu: '/treatmentService/medicalList/mail'
      },
      hidden: true
    }, {
      path: 'complex',
      name: 'medicalServiceListComplex',
      component: function component() {
        return import('@/views/treatmentService/list/complex');
      },
      meta: {
        title: '组合药品列表'
      }
    }, {
      path: 'editComplex',
      component: function component() {
        return import('@/views/treatmentService/list/editComplex');
      },
      name: 'medicalServiceComplexAction',
      meta: {
        title: '添加/编辑药品组合',
        noCache: true,
        activeMenu: '/treatmentService/medicalList/complex'
      },
      hidden: true
    }, {
      path: 'system',
      name: 'medicalServiceListSystem',
      component: function component() {
        return import('@/views/treatmentService/list/system');
      },
      meta: {
        title: '系统药品列表'
      }
    }, {
      path: 'mailReview',
      component: function component() {
        return import('@/views/treatmentService/list/mailDrugReview');
      },
      name: 'medicalServiceMailReview',
      meta: {
        title: '商城药品审核',
        noCache: true,
        activeMenu: '/treatmentService/medicalList/mail'
      },
      hidden: true
    }, {
      path: 'complexReview',
      component: function component() {
        return import('@/views/treatmentService/list/complexDrugReview');
      },
      name: 'medicalServiceComplexReview',
      meta: {
        title: '组合药品审核',
        noCache: true,
        activeMenu: '/treatmentService/medicalList/complex'
      },
      hidden: true
    }]
  }, {
    path: 'manage',
    name: 'medicalManageList',
    meta: {
      title: '管理'
    },
    component: function component() {
      return import('@/views/treatmentService/manage/index');
    },
    redirect: '/treatmentService/medical/manage/classification',
    children: [{
      path: 'classification',
      name: 'medicalManageClassification',
      component: function component() {
        return import('@/views/treatmentService/manage/classification');
      },
      meta: {
        title: '药品分类'
      }
    }, {
      path: 'medicalSort',
      name: 'medicalSort',
      component: function component() {
        return import('@/views/treatmentService/manage/medicalSort');
      },
      meta: {
        title: '药品顺序配置'
      }
    }, {
      path: 'dosageFormList',
      name: 'dosageFormList',
      component: function component() {
        return import('@/views/treatmentService/manage/dosageFormList');
      },
      meta: {
        title: '剂型库'
      }
    }, {
      path: 'distribution',
      name: 'medicalManageDistribution',
      component: function component() {
        return import('@/views/trade/logistics/setting/delivery');
      },
      meta: {
        title: '配送管理'
      }
    }, {
      path: 'starTagList',
      name: 'medicalManageStarTagList',
      component: function component() {
        return import('@/views/treatmentService/manage/starTagList');
      },
      meta: {
        title: '星火药品标签'
      }
    }]
  }, {
    path: 'dna',
    name: 'serviceOrderDna',
    meta: {
      title: '诺异安',
      groupTitle: "服务包"
    },
    redirect: '/treatmentService/dna/nurse',
    component: function component() {
      return import('@/views/treatmentService/serviceOrder/dna/index');
    },
    children: [{
      path: 'nurse',
      name: 'serviceOrderDnaNurse',
      component: function component() {
        return import('@/views/treatmentService/serviceOrder/dna/nurse');
      },
      meta: {
        title: '己绑护士列表'
      }
    }, {
      path: 'material',
      name: 'serviceOrderDnaMaterial',
      component: function component() {
        return import('@/views/treatmentService/serviceOrder/dna/material');
      },
      meta: {
        title: '素材管理'
      }
    }, {
      path: 'period',
      name: 'serviceOrderDnaPeriod',
      component: function component() {
        return import('@/views/treatmentService/serviceOrder/dna/period');
      },
      meta: {
        title: '期次管理'
      }
    }, {
      path: 'healthReport',
      name: 'serviceOrderDnaHealthReport',
      component: function component() {
        return import('@/views/treatmentService/serviceOrder/dna/healthReport');
      },
      meta: {
        title: '健康报告文案配置'
      }
    }]
  }, {
    path: 'setting',
    name: 'serviceOrderSetting',
    component: function component() {
      return import('@/views/treatmentService/serviceOrder/setting/index');
    },
    redirect: '/treatmentService/setting/classification',
    meta: {
      title: '管理'
    },
    alwaysShow: true,
    children: [{
      path: 'classification',
      component: function component() {
        return import('@/views/treatmentService/serviceOrder/setting/classification');
      },
      name: 'serviceOrderSettingClassification',
      meta: {
        title: '服务包分类'
      }
    }, {
      path: 'edit/:id(\\d+)',
      component: function component() {
        return import('@/views/treatmentService/serviceOrder/setting/edit');
      },
      name: 'serviceOrderSettingClassificationEdit',
      meta: {
        title: '编辑服务包',
        noCache: true,
        activeMenu: '/treatmentService/serviceOrder/setting/classification'
      },
      hidden: true
    }]
  }, {
    path: 'hostpitalList',
    name: 'medicalHostiptalList',
    component: function component() {
      return import('@/views/treatmentService/hospital/list/index');
    },
    redirect: '/treatmentService/hostpitalList/index',
    meta: {
      title: '医院列表',
      groupTitle: '医院'
    },
    children: [{
      path: 'index',
      component: function component() {
        return import('@/views/treatmentService/hospital/list/list');
      },
      name: 'medicalHostiptalListList',
      meta: {
        title: '医院列表'
      }
    }, {
      path: 'create',
      component: function component() {
        return import('@/views/treatmentService/hospital/list/create');
      },
      name: 'medicalHostiptalListCreate',
      meta: {
        title: '添加医院',
        icon: 'edit',
        roles: ['editor']
      },
      hidden: true
    }, {
      path: 'edit/:id(\\d+)',
      component: function component() {
        return import('@/views/treatmentService/hospital/list/edit');
      },
      name: 'medicalHostiptalListEdit',
      meta: {
        title: '编辑医院',
        noCache: true,
        activeMenu: '/treatmentService/hostiptal/list/list'
      },
      hidden: true
    }, {
      path: 'photos',
      component: function component() {
        return import('@/views/treatmentService/hospital/list/photos');
      },
      name: 'medicalHostiptalListPhotos',
      meta: {
        title: '医院图库'
      },
      hidden: true
    }]
  }, {
    path: 'hostiptalManage',
    name: 'medicalHostiptalManage',
    meta: {
      title: '管理'
    },
    redirect: '/treatmentService/hostiptalManage/classification',
    component: function component() {
      return import('@/views/treatmentService/hospital/manage/index');
    },
    children: [{
      path: 'classification',
      name: 'medicalHostiptalManageClassification',
      component: function component() {
        return import('@/views/treatmentService/hospital/manage/classification');
      },
      meta: {
        title: '医院分类'
      }
    }, {
      path: 'level',
      name: 'medicalHostiptalManageLevel',
      component: function component() {
        return import('@/views/treatmentService/hospital/manage/level');
      },
      meta: {
        title: '医院等级'
      }
    }, {
      path: 'service',
      name: 'medicalHostiptalService',
      component: function component() {
        return import('@/views/treatmentService/hospital/manage/service');
      },
      meta: {
        title: '设施服务'
      }
    }, {
      path: 'tag',
      name: 'medicalHostiptalTag',
      component: function component() {
        return import('@/views/treatmentService/hospital/manage/tag');
      },
      meta: {
        title: '印象标签'
      },
      hidden: true
    }, {
      path: 'photo',
      name: 'medicalHostiptalPhoto',
      component: function component() {
        return import('@/views/treatmentService/hospital/manage/photo');
      },
      meta: {
        title: '相册地点配置'
      }
    }]
  }, {
    path: 'departmentGrading',
    name: 'medicalDepartmentGrading',
    meta: {
      title: '分级科室'
    },
    component: function component() {
      return import('@/views/treatmentService/department/grading/index');
    },
    redirect: '/treatmentService/departmentGrading/index',
    hidden: true,
    children: [{
      path: 'grading',
      name: 'medicalDepartmentListGradingList',
      component: function component() {
        return import('@/views/treatmentService/department/grading/list');
      },
      meta: {
        title: '分级科室'
      },
      hidden: true
    }, {
      path: 'sort',
      name: 'medicalDepartmentGradingHospitalSortList',
      component: function component() {
        return import('@/views/treatmentService/department/grading/sort');
      },
      meta: {
        title: '绑定医院排序'
      },
      hidden: true
    }]
  }, {
    path: 'departmentList',
    name: 'medicalDepartmentList',
    component: function component() {
      return import('@/views/treatmentService/department/list/index');
    },
    redirect: '/treatmentService/departmentList/index',
    meta: {
      title: '科室库',
      groupTitle: "科室"
    },
    children: [{
      path: 'index',
      name: 'medicalDepartmentListList',
      component: function component() {
        return import('@/views/treatmentService/department/list/list');
      },
      meta: {
        title: '科室库'
      }
    }, {
      path: 'create',
      component: function component() {
        return import('@/views/treatmentService/department/list/create');
      },
      name: 'medicalDepartmentListCreate',
      meta: {
        title: '添加科室'
      },
      hidden: true
    }, {
      path: 'edit/:id(\\d+)',
      component: function component() {
        return import('@/views/treatmentService/department/list/edit');
      },
      name: 'medicalDepartmentListEdit',
      meta: {
        title: '编辑科室',
        noCache: true,
        activeMenu: '/treatmentService/department/list/list'
      },
      hidden: true
    }]
  }, {
    path: 'departmentManage',
    name: 'medicalDepartmentManage',
    component: function component() {
      return import('@/views/treatmentService/department/manage/index');
    },
    redirect: '/treatmentService/departmentManage/level',
    meta: {
      title: '管理'
    },
    alwaysShow: true,
    children: [{
      path: 'level',
      name: 'medicalDepartmentManageLevel',
      meta: {
        title: '科室等级'
      },
      component: function component() {
        return import('@/views/treatmentService/department/manage/level');
      }
    }]
  }, {
    path: 'diseaseList',
    name: 'medicalDiseaseList',
    meta: {
      title: '疾病列表',
      groupTitle: "疾病"
    },
    component: function component() {
      return import('@/views/treatmentService/disease/list/index');
    },
    redirect: '/treatmentService/diseaseList/index',
    children: [{
      path: 'index',
      name: 'medicalDiseaseListList',
      component: function component() {
        return import('@/views/treatmentService/disease/list/list');
      },
      meta: {
        title: '疾病列表'
      }
    }, {
      path: 'create',
      component: function component() {
        return import('@/views/treatmentService/disease/list/create');
      },
      name: 'medicalDiseaseListCreate',
      meta: {
        title: '添加疾病'
      },
      hidden: true
    }, {
      path: 'edit/:id(\\d+)',
      component: function component() {
        return import('@/views/treatmentService/disease/list/edit');
      },
      name: 'medicalDiseaseListEdit',
      meta: {
        title: '编辑疾病',
        noCache: true,
        activeMenu: '/treatmentService/disease/list/list'
      },
      hidden: true
    }]
  }, {
    path: 'diseaseManage',
    name: 'medicalDiseaseManage',
    meta: {
      title: '管理'
    },
    alwaysShow: true,
    component: function component() {
      return import('@/views/treatmentService/disease/manage/index');
    },
    redirect: '/treatmentService/diseaseManage/tag',
    children: [{
      path: 'tag',
      name: 'medicalDiseaseTag',
      meta: {
        title: '疾病标签'
      },
      component: function component() {
        return import('@/views/treatmentService/disease/manage/tag');
      }
    }]
  }, {
    path: 'symptomList',
    name: 'medicalSymptomList',
    meta: {
      title: '症状列表',
      groupTitle: '症状'
    },
    component: function component() {
      return import('@/views/treatmentService/symptom/list/index');
    },
    redirect: '/treatmentService/symptomList/index',
    children: [{
      path: 'index',
      name: 'medicalSymptomListList',
      component: function component() {
        return import('@/views/treatmentService/symptom/list/list');
      },
      meta: {
        title: '症状列表'
      }
    }, {
      path: 'create',
      component: function component() {
        return import('@/views/treatmentService/symptom/list/create');
      },
      name: 'medicalSymptomListCreate',
      meta: {
        title: '添加症状'
      },
      hidden: true
    }, {
      path: 'edit/:id(\\d+)',
      component: function component() {
        return import('@/views/treatmentService/symptom/list/edit');
      },
      name: 'medicalSymptomListEdit',
      meta: {
        title: '编辑症状',
        noCache: true,
        activeMenu: '/treatmentService/symptom/list/list'
      },
      hidden: true
    }]
  }, {
    path: 'symptomImport',
    name: 'medicalSymptomListImportTitle',
    meta: {
      title: '症状导入'
    },
    component: function component() {
      return import('@/views/treatmentService/symptom/importFile/index');
    },
    redirect: '/treatmentService/symptomImport',
    children: [{
      path: 'import',
      name: 'medicalSymptomListImport',
      component: function component() {
        return import('@/views/treatmentService/symptom/importFile/index');
      },
      meta: {
        title: '症状导入'
      }
    }]
  }, {
    path: 'search',
    name: 'searchList',
    meta: {
      title: '搜索词',
      groupTitle: '搜索词'
    },
    component: function component() {
      return import('@/views/treatmentService/search/index');
    },
    redirect: '/treatmentService/search/index',
    children: [{
      path: 'index',
      name: 'searchListList',
      component: function component() {
        return import('@/views/treatmentService/search/index');
      },
      meta: {
        title: '搜索词'
      }
    }]
  }, {
    path: 'import/:mode(\\w+)',
    component: function component() {
      return import('@/components/TableImport/TableImportFile/index');
    },
    name: 'tableImportFile',
    meta: {
      title: '导入流程'
    },
    hidden: true
  }]
};
export default medicalServiceRouter;