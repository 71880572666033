import Layout from '@/layout';
var merchantRouter = {
  path: '/merchantStore',
  component: Layout,
  redirect: '/merchantStore/merchantStoreInfo',
  name: 'merchantStore',
  meta: {
    title: '商家中心',
    icon: 'marketing'
  },
  alwaysShow: true,
  children: [{
    path: 'merchantStoreInfo',
    name: 'merchantStoreInfo',
    meta: {
      title: '店铺资料',
      groupTitle: '店铺管理'
    },
    redirect: '/merchantStore/merchantStoreInfo/storeInfo',
    component: function component() {
      return import('@/views/marketing/activity/index');
    },
    children: [{
      path: 'storeInfo',
      name: 'storeInfo',
      component: function component() {
        return import('@/views/merchant/store/storeInfo');
      },
      meta: {
        title: '店铺资料'
      }
    }]
  }, {
    path: 'merchantStoreManage',
    name: 'merchantStoreManage',
    component: function component() {
      return import('@/views/merchant/store/storeBrandManage');
    },
    meta: {
      title: '品牌管理'
    }
  }, {
    path: 'merchantAddBrand',
    name: 'merchantAddBrand',
    component: function component() {
      return import('@/views/merchant/store/addStoreBrand');
    },
    meta: {
      title: '添加品牌'
    },
    hidden: true
  }, {
    path: 'merchantStoreList',
    name: 'merchantStoreList',
    component: function component() {
      return import('@/views/merchant/store/storeDrugList');
    },
    meta: {
      title: '药品管理'
    }
  }, {
    path: 'addStoreDrug',
    name: 'addStoreDrug',
    component: function component() {
      return import('@/views/merchant/store/addStoreDrug');
    },
    meta: {
      title: '添加/编辑商城药品'
    },
    hidden: true
  }, {
    path: 'addStoreComplexDrug',
    name: 'addStoreComplexDrug',
    component: function component() {
      return import('@/views/merchant/store/addComplexDrug');
    },
    meta: {
      title: '添加/编辑组合药品'
    },
    hidden: true
  }]
};
export default merchantRouter;